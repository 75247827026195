import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import './Termsofuse.scss'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Dropdown from './../DropdownComponent'
import Container from '@material-ui/core/Container';

export default function TermsOfUse({ data, countryData, basePath }) {

  const allContent = documentToReactComponents(JSON.parse(data?.termsOfUseContent?.raw));
  const dropdownOrderList = countryData?.map(dropdownOrder => {
  const orderNumberwiseData = dropdownOrder?.orderNumberInDropDown;
    return {
      ...dropdownOrder,
      orderNumberwiseData: orderNumberwiseData
    };
  });
  function compareOrderDropdown(a, b) {
    return a.orderNumberwiseData - b.orderNumberwiseData;
  }
  dropdownOrderList.sort(compareOrderDropdown);

  return (
    <>
    <Container maxWidth="xl" className='terms-use-container-div'>
      <Grid container className="termsofuse-header-cls">
        <Grid item xl={1} lg={1} md={0} sm={0} xs={0}></Grid>
        <Grid item container xl={10} lg={11} md={12} sm={12} xs={12}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h1 className='header-title' locId="title" >
              {data?.title}
            </h1>
            <Box display={{ xs: "none", sm: "none", md:"block", lg:"block", xl:"block", }}>
            <h2 className='sub-title' locId="country" >
             {data?.country} 
            </h2>
            </Box>
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}
            className='terms-reverse-column'>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12} className='para-padding-right'>
            <Box display={{ xs: "block", sm: "block", md:"none", lg:"none", xl:"none", }}>
            <h2 className='sub-title' locId="country" >
             {data?.country} 
            </h2>
            </Box>
              <p className='paragraph' locId="termsOfUseContent">
                {allContent}
              </p>
            </Grid>
            <Grid item container xl={4} lg={4} md={4} sm={12} xs={12} className='terms-use-dropdown'>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="dropdown-div">
                <Dropdown
                  slug={data?.slug}
                  drodownListData={dropdownOrderList}
                  basePath={basePath}
                  SelectdropdownLabel ='termsOfUseLabel'
                  menuItemLocId= "termsOfUseLabel"  />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={1} lg={0} md={0} sm={0} xs={0}></Grid>
      </Grid>
      </Container>
    </>
  )
}


