import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import { graphql, navigate } from 'gatsby';
import TermsOfUsePage from '../components/TermsOfUse';
import SEO from '../components/seo-component/seo';

export default function TermsOfUse({ location, data, pageContext }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulTermsOfUse?.seoEntry}
          siteurl={data?.contentfulTermsOfUse?.slug}
          bucketName={pageContext?.appData?.adminDetails?.s3BucketName}
          basePath={pageContext.basePath}
          organizationName={pageContext?.appData?.organizationName}
          organizationLogo={pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink={pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink={pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink={pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <TermsOfUsePage
          data={data?.contentfulTermsOfUse}
          countryData={pageContext?.dropDownData}
          basePath={pageContext?.appData?.basePath}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query termsofuse($contentful_id: String, $locale: String) {
    contentfulTermsOfUse(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      termsOfUseLabel
      slug
      contentful_id
      country
      entryTitle
      termsOfUseContent {
        raw
      }
      title
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
    }
  }
`;
